import React from 'react'
const Other = () => {
  return (
    <div style={{textAlign:"center",color:"gray"}}>
        <h1 style={{fontWeight:"bolder",fontSize:"50px"}}>404</h1>
        <h1 style={{fontWeight:"bold",fontSize:"20px"}}>This Page Does Not Exist</h1>
    </div>
  )
}

export default Other
