
import Admin from './components/Admin';
import AllRoutes from './components/AllRoutes';

function App() {
  return (
    <div className="App">
      {/* <Admin/> */}
      <AllRoutes/>
    </div>
  );
}

export default App;
