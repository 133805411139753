import { Box, Button, FormControl, FormLabel, Input, Spinner,Textarea, useToast } from '@chakra-ui/react';
import JoditEditor from 'jodit-react';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const About = () => {
    const [isLoading,setIsLoading]=useState(false)
  const navigate=useNavigate()

    let url="https://api.srwater.in";
    const toast=useToast()
    const [detail,setDetail]=useState({
        subtitle:"",
        content:"",
        choose_subtitle:"",
        tagline_1:"",
        tagline_2:"",
        tagline_3:"",
        tagline_4:""
    })
    const config = useMemo(
        () => ({
          readonly: false,
          height: "400px",
        }),
        []
      );
  const editor = useRef(null);
    const handleChange=(e)=>{
        const{name,value}=e.target
        setDetail({...detail,[name]:value})
    }

    const getAbout=async()=>{
      try {
        let data=await fetch(`${url}/about`)
        data=await data.json()
        console.log(data.data);
        setDetail(data.data[0])
      } catch (error) {
        console.log(error);
      }
    }

    const editAbout=async()=>{
      console.log(detail._id);
      setIsLoading(true)
      try {
        let data=await fetch(`${url}/about/edit/${detail._id}`,{
          method: 'POST',
          headers:{
            "Content-Type":"application/json"
          },
          body: JSON.stringify(detail)
        })
        data=await data.json()
        toast({
          title: 'About Page is Updated',
          description: data.msg,
          status: 'success',
          position:"top",
          duration: 9000,
          isClosable: true,
        })
        navigate("/admin/page")
      } catch (error) {
        toast({
          title: 'Error In Uploading Image',
          description: error.message,
          status: 'error',
          position:"top",
          duration: 9000,
          isClosable: true,
        })
      }
      setIsLoading(false)
    }

    useEffect(()=>{
      getAbout()
    },[])
  return (
    <div>
        <Box backgroundColor={"white"}
        boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        padding={"20px"}
        borderRadius={"20px"}
        >
            <FormControl>
                <FormLabel>Subtitle</FormLabel>
                <Input value={detail?.subtitle} name="subtitle" onChange={(e)=>handleChange(e)} />
            </FormControl>
            <br/>
            <FormControl>
                <FormLabel>Content</FormLabel>
                <JoditEditor
            ref={editor}
            value={detail?.content}
            config={config}
            // tabIndex of textarea
            // preferred to use only this option to update the content for performance reasons
            onChange={(newContent) => setDetail({...detail, content: newContent})}
          />
            </FormControl>
            <br />
            <FormControl>
                <FormLabel>Why Choose Us Subtitle</FormLabel>
                <Textarea value={detail?.choose_subtitle} name="choose_subtitle" onChange={(e)=>handleChange(e)}/>
            </FormControl>
            <br/>
            <FormControl>
                <FormLabel>First Tagline</FormLabel>
                <Textarea value={detail?.tagline_1} name="tagline_1" onChange={(e)=>handleChange(e)}/>
            </FormControl>
            <br />
            <FormControl>
                <FormLabel>Second Tagline</FormLabel>
                <Textarea value={detail?.tagline_2} name="tagline_2" onChange={(e)=>handleChange(e)}/>
            </FormControl>
            <br />
            <FormControl>
                <FormLabel>Third Tagline</FormLabel>
                <Textarea value={detail?.tagline_3}  name="tagline_3"  onChange={(e)=>handleChange(e)} />
            </FormControl>
            <br/>
            <FormControl>
                <FormLabel>Forth Tagline</FormLabel>
                <Textarea value={detail?.tagline_4} name="tagline_4" onChange={(e)=>handleChange(e)}/>
            </FormControl>
            <br/>
        <br />
        <Button
        variant={"solid"}
        bgColor={"#161616"}
        color="white"
        _hover={{
          color: "black",
          bgColor: "white",
          border: "1px solid #161616",
        }}
        leftIcon={isLoading&&<Spinner color='blue.500' />}
        onClick={()=>editAbout()}
      >
        Save About
      </Button>
        </Box>
    </div>
  )
}

export default About