import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/checkbox.css"
import switchAudio from "../audio/light-switch.mp3"

const Home = () => {
  const [dataUrl, setDataUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const [image, setImage] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const navigate=useNavigate()
  const toast = useToast();
  let url = "https://api.srwater.in";
  let formData = new FormData();
  let audio = new Audio(switchAudio);


  const [detail, setDetail] = useState({
    top_heading: "",
    top_content: "",
    mission: "",
    values: "",
    vision: "",
    products: [],
    bottom_heading: "",
    bottom_content: "",
    bottom_image: "",
    contact_heading: "",
    contact_content: "",
  });

  const getCategory = async () => {
    try {
      let data = await fetch(`${url}/category`);
      data = await data.json();
      setCategory(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
  };

  const getCheck=useCallback((id)=>{
    audio.play()
    let exist=product.findIndex((e)=>e===id)
    if(exist>-1){
      return true
    }else{
      return false
    }
},[product])


  const handleCheck = (e) => {
    let updatedProduct=[...product]
    let index = updatedProduct.findIndex((i) => i == e._id);
    console.log(index);
    if (index > -1) {
      updatedProduct.splice(index, 1);
    } else {
      if (updatedProduct.length >= 6) {
        updatedProduct.pop();
      } else {
        // setProduct([...product, e._id]);
        updatedProduct.push(e._id);
      }
    }
    setProduct(updatedProduct);
  };
  const editHome = async (resImage) => {
    let updatedDetail={...detail}
    if (resImage) {
      updatedDetail={...updatedDetail,bottom_image:resImage}
    }
    if (product.length > 0) {
      updatedDetail={ ...updatedDetail, products: product };
    }
    setDetail(updatedDetail)
    try {
      let data = await fetch(`${url}/home/edit/${detail._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedDetail),
      });
      data = await data.json();
      console.log(data);
      toast({
        title: "Home Page Edited",
        description: data.msg,
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      navigate("/admin/page")
    } catch (error) {
      toast({
        title: "Error In Uploading Updates",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };
  const handleFileChanger = (e) => {
    let file = e.target.files[0];
    console.log(file);
    setImage(file);
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setDataUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const submitFile = async () => {
    if (dataUrl == "") {
      return;
    }
    formData.append("home", image);
    setIsLoading(true);
    try {
      let data = await axios.post(
        `https://api.srwater.in/home/image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(data.data.data);
      toast({
        title: "Image Uploaded Successfully",
        description: "",
        status: "success",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
      setImageUrl(data.data.data);
      setDetail({...detail,bottom_image:data.data.data});
      return data.data.data;
    } catch (error) {
      console.log(error);
      toast({
        title: "Error In Uploading Image",
        description: error.message,
        status: "error",
        position: "top",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const getHome = async () => {
    try {
      let data = await fetch(`${url}/home`);
      data = await data.json();
      setDetail(data.data[0]);
      setProduct(data.data[0].products);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getHome();
    getCategory();
  }, []);
  return (
    <div>
      <Box
        backgroundColor={"white"}
        boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        padding={"20px"}
        borderRadius={"20px"}
      >
        <FormControl>
          <FormLabel>Top Heading</FormLabel>
          <Input
            value={detail?.top_heading}
            name="top_heading"
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl>
          <FormLabel>Top Content</FormLabel>
          <Textarea
            value={detail?.top_content}
            maxLength={513}
            name="top_content"
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl>
          <FormLabel>Mission</FormLabel>
          <Textarea
            value={detail?.mission}
            name="mission"
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl>
          <FormLabel>Values</FormLabel>
          <Textarea
            value={detail?.values}
            name="values"
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl>
          <FormLabel>Vision</FormLabel>
          <Textarea
            value={detail?.vision}
            name="vision"
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl>
          <FormLabel>Products</FormLabel>
          <Text>Please Select only 6 Items</Text>
          <br />
          <Box>
            {category &&
              category.map((e) => {
                return (
                  <>
                    <Text fontWeight={"semibold"}>{e.name}</Text>
                    <br />
                    <SimpleGrid ml={["10%"]} columns={[1,2,3,3,4]} spacing={"40px"}>
                      {e.products.map((x, i) => (
                        <Flex gap="20px">
                          {/* <input
                            onChange={() => handleCheck(x)}
                            type="checkbox"
                            checked={getCheck(x._id)}
                          /> */}
                          <div class="checkbox-wrapper-55">
                            <label class="rocker rocker-small">
                              <input
                                type="checkbox"
                                onChange={() => handleCheck(x)}
                                checked={getCheck(x._id)}
                              />
                              <span class="switch-left">Yes</span>
                              <span class="switch-right">No</span>
                            </label>
                          </div>
                          <Text>{x.name}</Text>
                        </Flex>
                      ))}
                    </SimpleGrid>
                    <br />

                  </>
                );
              })}
          </Box>
        </FormControl>
        <br />
        <FormControl>
          <FormLabel>Bottom Heading</FormLabel>
          <Input
            value={detail?.bottom_heading}
            name="bottom_heading"
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <FormControl>
          <FormLabel>Bottom Content</FormLabel>
          <Textarea
            value={detail?.bottom_content}
            name="bottom_content"
            onChange={(e) => handleChange(e)}
          />
        </FormControl>
        <br />
        <Box>
          <FormControl isRequired>
            <FormLabel>Bottom Image</FormLabel>
            {dataUrl ? (
              <Image src={dataUrl} />
            ) : (
              <Image src={`${url}/home/${detail.bottom_image}`} />
            )}
            <br />
            <form encType="multipart/form-data">
              <input
                required
                type="file"
                name="home"
                onChange={(e) => handleFileChanger(e)}
              />
            </form>
            <Text>
              <span style={{ fontWeight: "bold" }}>Note</span>:File Size Should
              Be Less than 500KB and 500x500px size will allow Only
            </Text>
          </FormControl>
          <br />
          <FormControl>
            <FormLabel>Contact Heading</FormLabel>
            <Input
              value={detail?.contact_heading}
              name="contact_heading"
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <FormControl>
            <FormLabel>Contact Content</FormLabel>
            <Textarea
              value={detail?.contact_content}
              name="contact_content"
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <br />
          <Button
            variant={"solid"}
            bgColor={"#161616"}
            color="white"
            _hover={{
              color: "black",
              bgColor: "white",
              border: "1px solid #161616",
            }}
            leftIcon={isLoading && <Spinner color="blue.500" />}
            onClick={() => {
              submitFile().then((res) => editHome(res));
            }}
          >
            Save Home
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Home;
